// TODO: refactor to enum
export const MAIN_PAGE = '';
export const LOGIN_PAGE = 'login';
export const FORBIDDEN_PAGE = 'forbidden';
export const JOURNAL_PAGE = 'journal';
export const KANBAN_PAGE = 'kanban';
export const STATISTICS_PAGE = 'statistics';
export const DT_IMPORT_PAGE = 'dt-import';

export const KDT_IMPORT_PAGE = 'kdt-import';
export const DTS_PAGE = 'dts';

export const DTS_2_PAGE = 'dts2';
export const NOMENCLATURE = 'nomenclature';
export const USER = 'user';

export const CATALOGS_PAGE = 'catalogs';
